<template>
  <rcc-date-input
    v-bind="$attrs"
    hide-details
    v-on="$listeners"
    menu-class="inline-control"
  />
</template>

<script>
import RccDateInput from 'Components/controls/rcc-date-input'

export default {
  name: 'RccTableInlineDateInput',

  inheritAttrs: false,

  components: { RccDateInput }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  min-height: 25px !important;
  padding: 3px !important;
}

::v-deep .v-input__append-inner {
  margin-top: 1px !important;
  .v-btn {
    width: 19px !important;
    height: 19px !important;
  }

  .v-icon {
    font-size: 18px !important;
  }
}

::v-deep input {
  font-size: 13px !important;
  padding: 0 !important;
}
</style>
