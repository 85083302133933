export default {
  attention: {
    customFilter: {
      type: 'list',
      options: [
        { value: 1, text: 'Есть изменения' },
        { value: 2, text: 'Нет изменений' }
      ]
    }
  },

  booking_date: {
    customFilter: {
      type: 'datepicker'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '80px'
      }
    ]
  },

  booking_date_fact: {
    customFilter: {
      type: 'datepicker'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '80px'
      }
    ]
  },

  application_code: {
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ],
    customFilter: {
      type: 'search',
      label: 'Поиск по коду:',
      inputType: 'number'
    }
  },

  supplier: {
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по поставщику:'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '170px'
      }
    ]
  },

  pallets_num: {
    customFilter: {
      type: 'search',
      label: 'Введите количество:',
      inputType: 'number'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '80px'
      }
    ]
  },

  pallets_num_fact: {
    customFilter: {
      type: 'search',
      label: 'Введите количество:',
      inputType: 'number'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '80px'
      }
    ]
  },

  network: {
    customFilter: {
      type: 'list',
      options: []
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ]
  },

  region: {
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по региону'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ]
  },

  werk: {
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по РЦ'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '300px'
      }
    ]
  },

  return_point: {
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по точке возврата'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ]
  },

  store: {
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по складу:'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '150px'
      }
    ]
  },

  status: {
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Писк по статусу X5'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ]
  },

  cancel_reason: {
    customFilter: {
      type: 'search',
      label: 'Введите причину'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ]
  },

  return_status: {
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по статусу'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ]
  },

  status_detail: {
    customFilter: {
      type: 'autocomplete',
      label: 'Поиск по детализации статуса'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ]
  },

  number_1c: {
    customFilter: {
      type: 'search',
      label: 'Поиск по номеру 1С:'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '150px'
      }
    ]
  },

  comment: {
    customFilter: {
      type: 'search',
      label: 'Введите комментарий:'
    },
    colModifiers:[
      {
        type: 'max-width',
        value: '200px'
      }
    ]
  },

  ts_number: {
    customFilter: {
      type: 'search',
      label: 'Введите номер:'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '70px'
      }
    ]
  }
}
