import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('rcc-dialog',_vm._g(_vm._b({attrs:{"title":_vm.title,"value":_vm.value,"is-loading":_vm.isLoading,"max-height":"300px"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"columns"},_vm._l((_vm.localColumns),function(ref){
var id = ref.id;
var source = ref.source;
var enabled = ref.enabled;
return _c('rcc-checkbox',{key:id,attrs:{"label":source,"value":enabled},on:{"input":function($event){return _vm.handleCheckboxInput(id, !enabled)}}})}),1)]},proxy:true},{key:"footer",fn:function(){return [_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.handleConfirmClick}},[_vm._v(" Ок ")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.handleCancelClick}},[_vm._v(" Отмена ")])]},proxy:true}])},'rcc-dialog',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }