<template>
  <rcc-dialog
    :title="title"
    v-bind="$attrs"
    :value="value"
    :is-loading="isLoading"
    v-on="$listeners"
    max-height="300px"
  >
    <template #body>
      <div class="columns">
        <rcc-checkbox
          v-for="({ id, source, enabled }) in localColumns"
          :key="id"
          :label="source"
          :value="enabled"
          @input="handleCheckboxInput(id, !enabled)"
        />
      </div>
    </template>

    <template #footer>
      <v-spacer></v-spacer>

      <v-btn
        color="green darken-1"
        text
        @click="handleConfirmClick"
      >
        Ок
      </v-btn>

      <v-btn
        color="blue darken-1"
        text
        @click="handleCancelClick"
      >
        Отмена
      </v-btn>
    </template>
  </rcc-dialog>
</template>

<script>
import RccDialog from './dialog'
import rccCheckbox from 'Components/controls/rcc-checkbox'

export default {
  name: 'RccColumnsSettings',

  inheritAttrs: false,

  components: {
    RccDialog,
    rccCheckbox
  },

  props: {
    title: {
      type: String,
      default: 'Выберите колонки, которые будут отображаться в таблице'
    },

    value: {
      type: Boolean,
      default: false
    },

    saveCallback: {
      type: Function,
      default: null
    },

    columns: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      localColumns: [],
      isLoading: false
    }
  },

  watch: {
    value(isOpen) {
      if (isOpen) {
        this.localColumns = this.columns
      }
    }
  },

  methods: {
    handleCancelClick() {
      this.close()
    },

    handleCheckboxInput(id, value) {
      this.localColumns = this.localColumns.map(item => ({
        ...item,
        enabled: item.id === id ? value : item.enabled
      }))
    },

    async handleConfirmClick() {
      this.isLoading = true

      try {
        if (this.saveCallback) {
          await this.saveCallback({
            column_ids: this.localColumns
              .filter(({ enabled }) => enabled)
              .map(({ id }) => id)
          })
        }
      } catch {
        window.snackbar('Произошла ошибка при сохранении изменений', 'error')
      }

      this.$emit('update:columns', this.localColumns)
      this.isLoading = false
      this.close()
    },

    close() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  .columns {
    display: flex;
    flex-direction: column;
  }
}
</style>
