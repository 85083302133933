<template>
  <div class="header-selection" @click="handleCheckboxClick">
    <rcc-checkbox
      :value="isAllSelected"
      is-readonly
      :is-indeterminate="isIndeterminate"
    />
  </div>
</template>

<script>
import RccCheckbox from 'Components/controls/rcc-checkbox'

export default {
  name: 'RccHeaderSelection',

  components: { RccCheckbox },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    items: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    intersectingItems() {
      return this.items.filter(({ id }) => this.value.includes(id)).map(({ id }) => id)
    },

    differenceItems() {
      return this.items.filter(({ id }) => !this.value.includes(id)).map(({ id }) => id)
    },

    isAllSelected() {
      return !!this.items.length && !this.isIndeterminate && (this.intersectingItems.length === this.items.length)
    },

    isIndeterminate() {
      return !!this.intersectingItems.length && (this.intersectingItems.length !== this.items.length)
    }
  },

  methods: {
    handleCheckboxClick() {
      if (this.isAllSelected) {
        this.$emit('input', this.value.filter(id => !this.intersectingItems.includes(id)))
        return
      }

      this.$emit('input', this.value.concat(this.differenceItems))
    }
  }
}
</script>

<style lang="scss" scoped>
.header-selection {
  cursor: pointer;
  width: fit-content;

  .rcc-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: initial;
    height: 18px;

    ::v-deep .rcc-checkbox__checkbox {
      padding-left: 18px;
    }
  }
}
</style>
