<template>
  <rcc-number-input
    v-bind="$attrs"
    hide-details
    v-on="$listeners"
  />
</template>

<script>
import RccNumberInput from 'Components/controls/rcc-number-input'

export default {
  name: 'RccTableInlineNumberInput',

  inheritAttrs: false,

  components: { RccNumberInput }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  min-height: 25px !important;
  padding: 3px 20px 3px 3px !important;
}

::v-deep .v-input__append-inner {
  position: absolute;
  right: 0;
  top: -4px;

  .v-btn {
    width: 21px !important;
    height: 21px !important;
  }

  .v-icon {
    font-size: 20px !important;
  }
}

::v-deep input {
  font-size: 13px !important;
  padding: 0 !important;
}
</style>
