import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('rcc-row',_vm._b({directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.away),expression:"away"}],class:_vm.classes,attrs:{"headers":_vm.columns,"row":_vm.row},on:{"click":_vm.handleRowClick},scopedSlots:_vm._u([{key:"action-cell",fn:function(){return [_c('div',{staticClass:"action-cell"},[(_vm.isEditAccess && _vm.isReturnsManagementModeEnabled)?_c('div',[_c('rcc-checkbox',{ref:"checkboxRef",staticClass:"checkbox",attrs:{"input-value":_vm.row.id,"name":"is_cancel"},nativeOn:{"click":function($event){return _vm.handleCancelCheckboxClick.apply(null, arguments)}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):(_vm.isEditAccess && !_vm.isReturnsManagementModeEnabled)?_c('div',[_c(VBtn,{attrs:{"icon":"","small":"","disabled":_vm.isEditingEnabled},on:{"click":_vm.handleEditClick}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-lead-pencil ")])],1)],1):_vm._e()])]},proxy:true},{key:"booking_date-cell",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"date-fact"},[_vm._v(" "+_vm._s(value && _vm.$moment(value).format('DD.MM.YYYY'))+" ")])]}},{key:"booking_date_fact-cell",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"date-fact"},[(_vm.isEditingEnabled)?_c('rcc-table-inline-date-input',{class:_vm.bookingDateFactClasses,model:{value:(_vm.form.booking_date_fact),callback:function ($$v) {_vm.$set(_vm.form, "booking_date_fact", $$v)},expression:"form.booking_date_fact"}}):_c('span',[_vm._v(" "+_vm._s(value && _vm.$moment(value).format('DD.MM.YYYY'))+" ")])],1)]}},{key:"pallets_num_fact-cell",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"pallets-num-fact"},[(_vm.isEditingEnabled)?_c('rcc-table-inline-number-input',{class:_vm.palletsFactClasses,model:{value:(_vm.form.pallets_num_fact),callback:function ($$v) {_vm.$set(_vm.form, "pallets_num_fact", $$v)},expression:"form.pallets_num_fact"}}):_c('span',[_vm._v(" "+_vm._s(value)+" ")])],1)]}},{key:"return_point-cell",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"return-point"},[(_vm.isEditingEnabled)?_c('rcc-table-inline-select',{attrs:{"items":_vm.returnPointsForCurrentNetwork,"show-by":"name","list-attach":""},model:{value:(_vm.form.return_point_id),callback:function ($$v) {_vm.$set(_vm.form, "return_point_id", $$v)},expression:"form.return_point_id"}}):_c('span',[_vm._v(" "+_vm._s(value)+" ")])],1)]}},{key:"store-cell",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"store"},[(_vm.isEditingEnabled)?_c('rcc-table-inline-select',{attrs:{"show-by":"name","items":_vm.selectsOptions.stores || [],"list-attach":""},model:{value:(_vm.form.store_id),callback:function ($$v) {_vm.$set(_vm.form, "store_id", $$v)},expression:"form.store_id"}}):_c('span',[_vm._v(" "+_vm._s(value)+" ")])],1)]}},{key:"return_status-cell",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"return-status"},[(_vm.isEditingEnabled)?_c('rcc-table-inline-select',{attrs:{"show-by":"name","items":_vm.selectsOptions.returnStatuses || [],"list-attach":""},on:{"input":_vm.handleReturnStatusInput},model:{value:(_vm.form.return_status_id),callback:function ($$v) {_vm.$set(_vm.form, "return_status_id", $$v)},expression:"form.return_status_id"}}):_c('span',[_vm._v(" "+_vm._s(value)+" ")])],1)]}},{key:"status_detail-cell",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"details"},[(_vm.isEditingEnabled)?_c('rcc-table-inline-select',{attrs:{"show-by":"name","items":_vm.selectsOptions.details || [],"list-attach":""},model:{value:(_vm.form.status_detail_id),callback:function ($$v) {_vm.$set(_vm.form, "status_detail_id", $$v)},expression:"form.status_detail_id"}}):_c('span',[_vm._v(" "+_vm._s(value)+" ")])],1)]}},{key:"number_1c-cell",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"number-1c"},[(_vm.isEditingEnabled)?_c('rcc-table-inline-text-input',{model:{value:(_vm.form.number_1c),callback:function ($$v) {_vm.$set(_vm.form, "number_1c", $$v)},expression:"form.number_1c"}}):_c('span',[_vm._v(" "+_vm._s(value)+" ")])],1)]}},{key:"comment-cell",fn:function(ref){
var value = ref.value;
return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.row.comment),expression:"row.comment"}],staticClass:"comment"},[(_vm.isEditingEnabled)?_c('rcc-table-inline-text-input',{model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}}):_c('span',[_vm._v(" "+_vm._s(value)+" ")])],1)]}},{key:"row-end",fn:function(){return [_c('rcc-confirm-dialog',{attrs:{"is-show":_vm.isShowSubmitConfirm,"title":"Вы уверены, что  хотите сохранить изменения?","confirm-text":"Да","cancel-text":"Отмена","confirmCallback":_vm.submit},on:{"update:isShow":function($event){_vm.isShowSubmitConfirm=$event},"update:is-show":function($event){_vm.isShowSubmitConfirm=$event}}}),_c('rcc-confirm-dialog',{attrs:{"is-show":_vm.isShowCancelConfirm,"title":"Вы уверены, что хотите сбросить изменения?","confirm-text":"Сбросить","cancel-text":"Отмена","confirmCallback":function () { return _vm.$emit('editing-disabled'); }},on:{"update:isShow":function($event){_vm.isShowCancelConfirm=$event},"update:is-show":function($event){_vm.isShowCancelConfirm=$event}}})]},proxy:true}])},'rcc-row',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }