<template>
  <rcc-select
    v-bind="$attrs"
    hide-details
    menu-class="inline-control"
    v-on="$listeners"
  />
</template>

<script>
import RccSelect from 'Components/controls/rcc-select'

export default {
  name: 'RccTableInlineSelect',

  inheritAttrs: false,

  components: { RccSelect }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  min-height: 25px !important;
  padding: 3px !important;
}

::v-deep .v-input__append-inner {
  position: absolute;
  right: 0;
  top: -9px;

  .v-btn {
    width: 19px !important;
    height: 19px !important;
  }

  .v-icon {
    font-size: 18px !important;
  }
}

::v-deep .v-select__selections,
::v-deep .v-select__selection--comma {
  font-size: 13px !important;
  padding: 0 !important;
  margin: 0 !important;
}

::v-deep input {
  padding: 0 !important;
}
</style>
